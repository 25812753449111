<template>
    <div class="view-paper">
        <van-tabs v-model="tabData" class="tab-view">
            <van-tab title="发给我的">
                <div class="list" ref="ListWare">
                    <div class="case-filter" v-if="!isShowQueryField">
                        <van-checkbox class="check-unread" v-model="isUnread">只看未读</van-checkbox>
                        <span class="filter-right" @click.stop="handlerFilter">
                            <i class="iconfont icon-intact-funnel"></i>
                            筛选
                        </span>
                    </div>
                    <query-field v-if="isShowQueryField" @resetQuery="resetQuery" :tabData="tabData" :tempQueryParam="tempQueryParam" :allUserList="allUserList" :lookNameList="lookNameList" :writeNameList="writeNameList" :subordinateList="subordinateList"></query-field>
                    <filter-popup v-if="showFilterPop && (lookNameList.length> 0 || writeNameList.length> 0 ||subordinateList.length> 0)" :ctrlFilterPop.sync="showFilterPop" :ctrlCtIdPop.sync="showCtIdPop" :queryParam="queryParam" :lookNameList="tabData === 0 ? lookNameList : tabData === 2 ? subordinateList : writeNameList" :allUserList="allUserList" @watchFilter="watchFilter"></filter-popup>
                    <div class="scrollBox" ref="Groups">
                        <scroll class="wrapper" v-if="tabData === 0" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp" @handelScroll="handelScroll">
                            <div class="moduleCodePage">
                                <template v-for="(item, index) in paperDetailList" @touchstart="touchstart(index)" @touchend="touchend(index)">
                                    <record-list :key="index" ref="RecordContent" :ctrlExtend.sync="isShowExtend" :actionOption="actionOption" :tabData="tabData" :paperDetail="item" @targetData="targetData" @paperData="paperData" @delPaper="delPaper" @findSomeoneAll="findSomeoneAll" @changeStatus="changeStatus"></record-list>
                                </template>
                            </div>
                        </scroll>
                        <loading-new :loading="isLoading"></loading-new>
                    </div>
                    <transition name="van-fade">
                        <nodata v-if="paperDetailList.length <= 0 && !isLoading"></nodata>
                    </transition>
                </div>
            </van-tab>
            <van-tab title="我发出的">
                <div class="list" ref="ListWare">
                    <div class="case-filter" v-if="!isShowQueryField">
                        <span class="pull-right" @click.stop="handlerFilter">
                            <i class="iconfont icon-intact-funnel"></i>
                            筛选
                        </span>
                    </div>
                    <query-field v-if="isShowQueryField" @resetQuery="resetQuery" :tabData="tabData" :tempQueryParam="tempQueryParam" :allUserList="allUserList" :lookNameList="lookNameList" :writeNameList="writeNameList" :subordinateList="subordinateList"></query-field>
                    <filter-popup v-if="showFilterPop && (lookNameList.length> 0 || writeNameList.length> 0 ||subordinateList.length> 0)" :ctrlFilterPop.sync="showFilterPop" :ctrlCtIdPop.sync="showCtIdPop" :queryParam="queryParam" :lookNameList="tabData === 0 ? lookNameList : tabData === 2 ? subordinateList : writeNameList" :allUserList="allUserList" @watchFilter="watchFilter"></filter-popup>
                    <div class="scrollBox" ref="Groups">
                        <scroll class="wrapper" v-if="tabData === 1" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp" @handelScroll="handelScroll">
                            <div class="moduleCodePage">
                                <template v-for="(item, index) in paperDetailList" @touchstart="touchstart(index)" @touchend="touchend(index)">
                                    <record-list :key="index" ref="RecordContent" :ctrlExtend.sync="isShowExtend" :actionOption="actionOption" :tabData="tabData" :paperDetail="item" @targetData="targetData" @paperData="paperData" @delPaper="delPaper" @findSomeoneAll="findSomeoneAll" @changeStatus="changeStatus"></record-list>
                                </template>
                            </div>
                        </scroll>
                        <loading-new :loading="isLoading"></loading-new>
                    </div>
                    <transition name="van-fade">
                        <nodata v-if="paperDetailList.length <= 0 && !isLoading"></nodata>
                    </transition>
                </div>
            </van-tab>
            <!-- <van-tab title="我下属的">
                <div class="list" ref="ListWare">
                    <div class="case-filter" v-if="!isShowQueryField">
                        <span class="pull-right" @click.stop="handlerFilter">
                            <i class="iconfont icon-intact-funnel"></i>
                            筛选
                        </span>
                    </div>
                    <query-field v-if="isShowQueryField" @resetQuery="resetQuery" :tabData="tabData" :tempQueryParam="tempQueryParam" :allUserList="allUserList" :lookNameList="lookNameList" :writeNameList="writeNameList" :subordinateList="subordinateList"></query-field>
                    <filter-popup v-if="showFilterPop && (lookNameList.length> 0 || writeNameList.length> 0 ||subordinateList.length> 0)" :ctrlFilterPop.sync="showFilterPop" :ctrlCtIdPop.sync="showCtIdPop" :queryParam="queryParam" :lookNameList="tabData === 0 ? lookNameList : tabData === 2 ? subordinateList : writeNameList" :allUserList="allUserList" @watchFilter="watchFilter"></filter-popup>
                    <div class="scrollBox" ref="Groups">
                        <scroll class="wrapper" v-if="tabData === 2" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp" @handelScroll="handelScroll">
                            <div class="moduleCodePage">
                                <template v-for="(item, index) in paperDetailList" @touchstart="touchstart(index)" @touchend="touchend(index)">
                                    <record-list :key="index" ref="RecordContent" :ctrlExtend.sync="isShowExtend" :actionOption="actionOption" :tabData="tabData" :paperDetail="item" @targetData="targetData" @paperData="paperData" @delPaper="delPaper" @findSomeoneAll="findSomeoneAll" @changeStatus="changeStatus"></record-list>
                                </template>
                            </div>
                        </scroll>
                        <loading-new :loading="isLoading"></loading-new>
                    </div>
                    <transition name="van-fade">
                        <nodata v-if="paperDetailList.length <= 0 && !isLoading"></nodata>
                    </transition>
                </div>
            </van-tab> -->
        </van-tabs>
        <van-popup v-model="showCtIdPop" @click-overlay="onCancelCtId" :close-on-click-overlay="false" position="bottom">
            <van-picker show-toolbar title="" :columns="allUserList" value-key="realName" @cancel="onCancelCtId" @confirm="onConfirmCtId" />
        </van-popup>
        <paper-detail v-if="showPaperDetail" :tabData="tabData" :companyObj="companyObj" :ctrlPaperDetailPop.sync="showPaperDetail" :tempPaperDetail="tempPaperDetail" @findSomeoneAll="findSomeoneAll" @detailDelPaper="delPaper" @targetData="targetData" @changeStatus="changeStatus"></paper-detail>
        <target-detail v-if="showTargetDetail" :ctrlDetailPop.sync="showTargetDetail" :targetDetail="targetDetail"></target-detail>
        <van-action-sheet class="action-ctrl" v-model="isShowExtend" :actions="actionOption" cancel-text="取消" @select="handlerSelect" @cancel="isShowExtend = false" />
    </div>
</template>

<script>
export default {
    name: 'ViewLog',
    props: {},
    components: {
        'record-list': resolve => require(['./RecordList/index'], resolve),
        'query-field': resolve => require(['../Vue/QueryField/index'], resolve),
        'filter-popup': resolve => require(['../Vue/FilterPopup/index'], resolve),
        'target-detail': resolve => require(['../Vue/TargetDetail/index'], resolve),
        'paper-detail': resolve => require(['../Vue/PaperDetail/index'], resolve),
        'scroll': resolve => require(['@/components/Scroll/index'], resolve),
        'loading-new': resolve => require(['@/page/Client/CustPublic/LoadingVue/index.vue'], resolve)
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],
            // 以上-
            isShowExtend: false,
            actionOption: [
                {
                    name: '查看全部日志',
                    action: 'viewAll',
                    paperId: '',
                    detailId: '',
                    ctId: ''
                },
                {
                    name: '删除',
                    action: 'del',
                    paperId: '',
                    detailId: '',
                    ctId: ''
                }
            ],
            tabData: 0,
            queryParam: {
                type: 'all',
                paperId: '',
                from: 0,
                size: 15,
                start: '',
                end: '',
                ctId: ''
            },
            startTime: new Date(),
            endTime: new Date(),
            pageNum: 1,
            paperDetailList: [],
            isLoading: false, // 控制是否显示loading图
            isLoadingMore: false, // 控制上拉加载
            isUnread: false,
            showFilterPop: false,
            lookNameList: [],
            writeNameList: [],
            subordinateList: [],
            allUserList: [],
            isShowQueryField: false,
            showTargetDetail: false,
            showPaperDetail: false,
            tempQueryParam: {}, // 筛选字段展示
            targetDetail: {},
            tempPaperDetail: {},
            showCtIdPop: false,
            companyObj: {}
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    watch: {
        'tabData': {
            handler(newV, oldV) {
                if (newV !== oldV) {
                    this.handlerTabData(newV)
                }
            }
        },
        'isUnread': {
            handler(newV, oldV) {
                if (newV) {
                    this.queryParam = {
                        type: 'unread',
                        paperId: '',
                        from: 0,
                        size: 15,
                        start: '',
                        end: '',
                        ctId: ''
                    }
                } else {
                    this.queryParam = {
                        type: 'all',
                        paperId: '',
                        from: 0,
                        size: 15,
                        start: '',
                        end: '',
                        ctId: ''
                    }
                }
                this.pageNum = 1
                this.paperDetailList = []
                this.getQueryPapers()
            }
        },
        'showTargetDetail': {
            handler(newV, oldV) {
                if (newV) {
                    this.setHeaderTitle(this.targetDetail.targetTitle)
                } else {
                    this.setHeaderTitle('看日志')
                }
            }
        },
        '$route'(to, from) {
            if (to.path === '/salelog/viewLog') {
                this.setHeaderTitle('看日志')
                this.Global.utils.rightMenu.clearMenu()
                // this.$refs.scroll.forceUpdate()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    created() {
        this.getCompanysetting()
    },
    mounted() {
        this.setHeaderTitle('看日志')
        this.Global.utils.rightMenu.setMenu('') // 清空右上角菜单
        this.getQueryPapers()
        this.getAllSendPersons()
        try {
            this.$nextTick(() => {
                // this.$refs.ListWare && this.$refs.ListWare.addEventListener('touchmove', this.handelScroll)
            })
        } catch (err) {
            console.log(err)
        }
    },
    methods: {
        async handlerSelect(item) {
            if (item.action === 'viewAll') {
                // 查看全部日志
                this.findSomeoneAll(item.ctId)
            } else if (item.action === 'del') {
                // 删除
                try {
                    let param = {
                        paperId: item.paperId,
                        detailId: item.detailId
                    }
                    let url = this.Global.config.apiBaseURL + this.Global.api.v1.handle_paper
                    let res = await this.axios.delete(url, { params: param })
                    res = res.data
                    if (res.code.toString() === this.Global.config.RES_OK) {
                        this.delPaper(item.paperId, item.detailId)
                    } else {
                        this.$toast.fail(res.msg)
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.isShowExtend = false
        },
        touchend(index) {
            let dom = document.getElementsByClassName('moduleCodePage')
            dom[index].style.backgroundColor = 'white'
        },
        touchstart(index) {
            let dom = document.getElementsByClassName('moduleCodePage')
            dom[index].style.backgroundColor = '#fcf2f3'
        },
        async onPullingDown() {
            // 下拉刷新
            // this.queryParam = {
            //     type: 'all',
            //     paperId: '',
            //     from: 0,
            //     size: 15,
            //     start: '',
            //     end: '',
            //     ctId: ''
            // }
            this.pageNum = 1
            this.paperDetailList = []
            await this.getQueryPapers()
            this.$nextTick(() => {
                // this.$refs.scroll.cusScrollTo()
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            // 上拉加载
            // console.log(this.isLoadingMore, this.pageNum, this.paperDetailList.length)
            if (!this.isLoadingMore) {
                await this.getQueryPapers()
                this.$nextTick(() => {
                    this.$refs.scroll.forceUpdate(true) // 加载更多
                })
            } else {
                if (this.paperDetailList.length !== 0) {
                    this.$nextTick(() => {
                        this.$refs.scroll.forceUpdate(false) // 没有更多数据
                    })
                }
            }
        },
        // 获取企业配置信息
        getCompanysetting() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.account_get, { params: {} })
                .then(res => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        this.companyObj = res.data.data
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        onCancelCtId() {
            this.showCtIdPop = false
        },
        onConfirmCtId(val, index) {
            this.queryParam.ctId = val.ctId
            this.showCtIdPop = false
        },
        handlerTabData(newV) {
            try {
                this.queryParam = {
                    type: 'all',
                    paperId: '',
                    from: 0,
                    size: 15,
                    start: '',
                    end: '',
                    ctId: ''
                }
                switch (newV) {
                    case 0:
                        this.isUnread = false
                        this.queryParam.type = 'all' // 发给我的
                        break
                    case 1:
                        this.queryParam.type = 'sendOut' // 我发出的
                        break
                    case 2:
                        this.queryParam.type = 'subordinate' // 我下属的
                        break
                    default:
                        break
                }
                this.tempQueryParam = {}
                if (this.isShowQueryField) this.isShowQueryField = false
                if (this.showFilterPop) this.showFilterPop = false
                this.isLoading = false
                this.isLoadingMore = false
                this.pullDownRefresh = true
                this.pullUpLoad = true
                this.pageNum = 1
                this.paperDetailList = []
                this.getQueryPapers()
                this.$refs.scroll.cusScrollTo()
                // this.$refs.ListWare.scrollTo(0, 0)
            } catch (err) {
                console.log(err)
            }
        },
        async getAllSendPersons() {
            // all 全部发送人
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_send_user
                let res = await this.axios.get(url, { params: { type: 'all' } })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        this.allUserList = [...res.data]
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        watchFilter(arg, n) {
            try {
                // n 1:重置, 2:确定筛选
                let tempObj = JSON.parse(JSON.stringify(arg))
                if (tempObj.paperId) {
                    if (this.tabData === 0) {
                        // 发给我的
                        tempObj.type = 'temp'
                    } else if (this.tabData === 1) {
                        // 我发出的
                        tempObj.type = 'sendOut'
                    } else if (this.tabData === 2) {
                        // 我下属的
                        tempObj.type = 'subordinate'
                    }
                }
                this.queryParam = JSON.parse(JSON.stringify(tempObj))
                this.tempQueryParam = JSON.parse(JSON.stringify(tempObj))
                if (n === 2) {
                    this.pageNum = 1
                    this.paperDetailList = []
                    this.getQueryPapers()
                    this.isShowQueryField = true
                } else if (n === 1) {
                    this.resetQuery()
                }
                this.showFilterPop = false
            } catch (err) {
                console.log(err)
            }
        },
        getRealName(id, n) {
            if (id) {
                if (n === 1) return this.allUserList.filter(v => v.ctId === id)[0].realName
                if (n === 2) {
                    if (this.tabData === 0) {
                        return this.lookNameList.filter(v => v.paperId === id)[0].name
                    } else if (this.tabData === 1) {
                        return this.writeNameList.filter(v => v.paperId === id)[0].name
                    } else if (this.tabData === 2) {
                        return this.subordinateList.filter(v => v.paperId === id)[0].name
                    }
                }
            }
        },
        resetQuery() {
            this.isShowQueryField = false
            this.handlerTabData(this.tabData)
        },
        handlerFilter() {
            if (this.tabData === 0) {
                // 发给我的
                if (this.lookNameList.length <= 0) {
                    this.getPaperName(1)
                } else {
                    this.showFilterPop = true
                }
            } else if (this.tabData === 1) {
                // 我发出的
                if (this.writeNameList.length <= 0) {
                    this.getPaperName(2)
                } else {
                    this.showFilterPop = true
                }
            } else if (this.tabData === 2) {
                // 我下属的
                if (this.subordinateList.length <= 0) {
                    this.getPaperName(3)
                } else {
                    this.showFilterPop = true
                }
            }
        },
        // 获取日志名称 1: look(发给我的), 2: write(我发出的/写日志), 3: subordinate(我下属的)
        async getPaperName(id, from) {
            try {
                let param = {}
                switch (id) {
                    case 1:
                        param.type = 'look'
                        break
                    case 2:
                        param.type = 'write'
                        break
                    case 3:
                        param.type = 'subordinate'
                        break
                    default:
                        break
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_name
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        if (id === 1) {
                            this.lookNameList = [...res.data]
                            this.showFilterPop = true
                        }
                        if (id === 2) {
                            this.writeNameList = [...res.data]
                            this.showFilterPop = true
                        }
                        if (id === 3) {
                            this.subordinateList = [...res.data]
                            this.showFilterPop = true
                        }
                    } else {
                        this.showFilterPop = false
                        if (id === 1) {
                            this.lookNameList = []
                        } else if (id === 2) {
                            this.writeNameList = []
                            // this.isShowWriteLog = false
                            // // 您还没有要填写的日志, 如需要请联系管理员添加
                            // if (!from) this.$message.warning(this.$t('mxpcweb.sale.log.1563971254004'))
                        } else {
                            this.subordinateList = []
                        }
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        targetData(arg) {
            this.targetDetail = JSON.parse(JSON.stringify(arg))
            this.showTargetDetail = true
        },
        paperData(arg) {
            this.tempPaperDetail = arg
            this.showPaperDetail = true
        },
        delPaper(paperId, detailId) {
            this.paperDetailList.splice(this.paperDetailList.findIndex(item => item.paperId === paperId), 1)
        },
        findSomeoneAll(ctId) {
            this.pageNum = 1
            this.paperDetailList = []
            this.queryParam = {
                type: 'all',
                paperId: '',
                from: 0,
                size: 15,
                start: '',
                end: '',
                ctId: ctId
            }
            this.tempQueryParam['ctId'] = ctId
            this.isShowQueryField = true
            this.getQueryPapers()
        },
        changeStatus(paperId, detailId, refreshViewer) {
            try {
                // refreshViewer 点击 已读 更新
                // 头像暂无，调接口获取
                let viewer = {
                    avatar: this.companyObj.avatar,
                    realName: this.companyObj.realName
                }
                this.paperDetailList = this.paperDetailList.map(v => {
                    let tempV = { ...v }
                    if (tempV.paperId === paperId && tempV.detailId === detailId) {
                        if (tempV.status != 1) { // 防止重复写入
                            tempV.status = 1
                            tempV.viewer.push(viewer)
                            if (this.showPaperDetail) this.tempPaperDetail = tempV // 点击查看详情时同步数据
                            // if (refreshViewer && refreshViewer.length > 0) {
                            //     tempV.viewer = refreshViewer
                            // } else {
                            //     tempV.viewer.push(viewer)
                            // }
                        }
                    }
                    return tempV
                })
            } catch (err) {
                console.log(err)
            }
        },
        async getQueryPapers() {
            try {
                if (this.pageNum === 1) this.isLoading = true
                this.isLoadingMore = true
                const url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_view
                this.queryParam.from = (this.pageNum - 1) * this.queryParam.size
                let res = await this.axios.get(url, { params: this.queryParam })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.detail.length > 0) {
                        this.paperDetailList = [...this.paperDetailList, ...res.data.detail]
                        if (this.queryParam.size === res.data.detail.length) {
                            this.pageNum++
                            this.isLoadingMore = false
                        }
                        this.$nextTick(() => {
                            // this.$refs.ListWare && this.$refs.ListWare.addEventListener('touchmove', this.handelScroll) // 重新挂载事件
                            // this.handelScroll()
                        })
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
                this.isLoading = false
            } catch (err) {
                console.log(err)
            }
        },
        handelScroll() {
            try {
                let paperCardArr = this.$refs.RecordContent
                if (paperCardArr) {
                    for (let i = 0; i < paperCardArr.length; i++) {
                        if (this.tabData === 0) paperCardArr[i].vertifyViewPort()
                    }
                }
                // let wholeH = this.$refs.Groups.$el.offsetHeight
                let wholeH = this.$refs.Groups.offsetHeight
                let scrollH = this.$refs.ListWare.scrollTop
                let viewH = this.$refs.ListWare.offsetHeight
                let toBottomGap = wholeH - scrollH - viewH
                // 到底部距离小于200时加载
                if (wholeH > viewH && toBottomGap <= 200 && !this.isLoadingMore) {
                    console.log('loading')
                    this.getQueryPapers()
                }
            } catch (err) {
                console.log('err')
            }
        }
    },
    beforeDestroy() {
        if (this.showFilterPop) this.showFilterPop = false
    },
    beforeRouteLeave(to, from, next) {
        if (this.showFilterPop) this.showFilterPop = false
        if (this.showTargetDetail) {
            this.showTargetDetail = false
            if (to.path === '/app') {
                // back
                next(false)
            } else {
                // 指标跳转
                next()
            }
        } else if (this.showPaperDetail) {
            this.showPaperDetail = false
            next(false)
        } else {
            next()
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
